@tailwind base;
@tailwind components;
@tailwind utilities;

.btn{
    @apply bg-primary
    py-[18px] px-[41px] 
    text-white rounded-[8px]
    text-[20px]
}

.btn-sm{
    @apply bg-primary
    py-[14px] px-[22px] 
    text-white rounded-[8px]
    text-[14px]
}

.btn-md{
    @apply bg-primary
    py-[19px] px-[32px] 
    text-white rounded-[8px]
    text-[14px]
}

.active{
    @apply underline underline-offset-8
    decoration-2
}