.wrapper-background {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image {
  background: url("../assets/images/real_background_1 16.png") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it's behind all content */
  opacity: 1; /* Adjust the opacity as needed */
}

.content {
  position: relative;
  z-index: 1; /* Ensure the content is above the background */
}
